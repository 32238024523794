<template>
  <el-dialog title="开收款单" width="780px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <div v-if="bankObj.bank_name" class="f-s-11 m-b-5" style="margin-left: 120px">
        <span class="m-r-10">
          <span>账户简称：</span>
          <span class="c-gray-light">{{ bankObj.bank_short_name }}</span>
        </span>
        <span class="m-r-10">
          <span>收款单位：</span>
          <span class="c-gray-light">{{ bankObj.bank_company }}</span>
        </span>
        <span class="m-r-10">
          <span>银行账号：</span>
          <span class="c-gray-light">{{ bankObj.bank_code }}</span>
        </span>
        <span class="m-r-10">
          <span>收款银行：</span>
          <span class="c-gray-light">{{ bankObj.bank_name }}</span>
        </span>
      </div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfData"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交审核</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    billId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        bill_id: this.billId,
        bill_type: '收款单',
        bank_name: '',
        bank_id: '',
        collection_type: '',
        amount_received: '',
        remark: '',
        cash_photo_list: []
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'select',
          title: '收款账户',
          field: 'bank_name',
          required: true,
          options: [],
          fnChange: () => {
            this.setBank()
          }
        },
        {
          type: 'select',
          title: '收款类型',
          field: 'collection_type',
          required: true,
          options: [
            {
              key: '定金',
              val: '定金'
            },
            {
              key: '尾款',
              val: '尾款'
            },
            {
              key: '全款',
              val: '全款'
            }
          ]
        },
        {
          type: 'text',
          title: '收款金额(元)',
          field: 'amount_received',
          required: true
        },
        {
          type: 'text',
          title: '备注',
          field: 'remark'
        },
        {
          type: 'upload',
          title: '客户付款凭证',
          field: 'cash_photo_list',
          isDelete: true
        }
      ],
      rules: {
        bank_name: [{ required: true, message: '请选择收款账户' }],
        collection_type: [{ required: true, message: '请选择收款类型' }],
        amount_received: [{ required: true, message: '请输入收款金额' }]
      },
      bankList: [],
      bankObj: {}
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getBankList()
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/reportBill/saveOrUpdateByCash',
            data: this.selfData
          })
            .then((res) => {
              if (res.data.state === 'ok') {
                this.popupCancel()
                this.$emit('success')
                this.$message.success('保存成功')
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        }
      })
    },
    // 获取收款账号列表
    getBankList() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bank/list',
        data: {
          currentPage: 1,
          pageSize: this.$constant.pageMax,
          sortData: {
            id: 'desc'
          }
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.bankList = this.$util.deepCopy(res.data.page.list)
          this.formColumn.forEach((formItem) => {
            if (formItem.field === 'bank_name') {
              formItem.options = this.bankList.map((bankItem) => {
                return {
                  key: bankItem.bank_name,
                  val: bankItem.bank_name
                }
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 设置收款
    setBank() {
      if (!this.selfData.bank_name) this.bankObj = {}
      this.bankList.forEach((item) => {
        if (item.bank_name === this.selfData.bank_name) {
          this.selfData.bank_id = item.id
          this.bankObj = this.$util.deepCopy(item)
        }
      })
    }
  }
}
</script>

<style></style>
