import { render, staticRenderFns } from "./popup-apply-purchase.vue?vue&type=template&id=d64c87ae&scoped=true"
import script from "./popup-apply-purchase.vue?vue&type=script&lang=js"
export * from "./popup-apply-purchase.vue?vue&type=script&lang=js"
import style0 from "./popup-apply-purchase.vue?vue&type=style&index=0&id=d64c87ae&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d64c87ae",
  null
  
)

export default component.exports